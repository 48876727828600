import React from 'react'

const Loading = () => {
  return (
    <div className='user-list-loader-container' >
        <div className="loader"></div>  
    </div>
  )
}

export default Loading