import React, { createContext, useContext, useState } from 'react'
import AuthContext from './AuthContext';

const ProfileCompletedPercentageContext = createContext();
export default ProfileCompletedPercentageContext;

export const ProfileCompletedPercentageProvider = ({ children }) => {
    
    const [completedPercentage, setCompletedPercentage] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const { authToken } = useContext(AuthContext)
    let userAccess;
    if (authToken){
        userAccess = authToken.access
    }
    

    const getProfileCompletedPercentage = async() => {
        setIsLoading(true)
        const response = await fetch('/api/user/get_profile_completed_percentage/', {
            method: "GET",
            headers: {
                "content-type": "Application/json",
                "Authorization": `JWT ${userAccess}`
            }
        });
        const data = await response.json()
        if (response.ok){
            setCompletedPercentage(data)
        }
        setIsLoading(false)
    }

    const contextData = {
        getProfileCompletedPercentage,
        completedPercentage,
        isLoading
    }

    return (
        <ProfileCompletedPercentageContext.Provider value={contextData}>
            { children }
        </ProfileCompletedPercentageContext.Provider>
    )
}