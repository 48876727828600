import React, { useState } from 'react'
import RateReviewIcon from '@mui/icons-material/RateReview';
import Button from './Button/Button';
import Select from "react-select";
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close';
import AlertNotification from './AlertNotification';
import Loading from './Loading';
import AllComments from './AllComments';

const ProjectComment = () => {

    const [isAddCommentContainerOpen, setIsAddCommentContainerOpen] = useState(false)
    const [selectedName, setSelectedName] = useState("Write as")
    const [formValue, setFormValue] = useState([])
    const [alertResponse, setAlertResponse] = useState()
    const [selectFieldAlert, setSelectFieldAlert] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isSeeAllComment, setIsSeeAllComment] = useState(false)

    const nameSelection = ["Anonymous", "Name"]

    const {register, handleSubmit, reset, clearErrors,
        formState: {
          errors, isSubmitting
        }} = useForm({
          defaultValues: {
            "name": "",
            "comment": "",
          }
        })

    const onClickAddComment = () => {
        setIsAddCommentContainerOpen(true)
        document.body.classList.add('disable-scroll');
    }

    const onClickCancel = (type) => {
        setIsAddCommentContainerOpen(false)
        setSelectedName(() => "Write as")
        setSelectFieldAlert()
        setFormValue({
            "name": "",
            "comment": ""
        });
        reset()
        document.body.classList.remove('disable-scroll');
    }

    const onClickSave = async(e) => {
        setIsLoading(true)
        if (selectedName === "Write as"){
            setIsLoading(false)
            return setSelectFieldAlert("Please select Anonymous or Name")
        }

        let sendData;
        if (selectedName === "Anonymous"){
            sendData = {"name": "Anonymous", "comment": formValue["comment"]}
        } else {
            sendData = formValue
        }
        const response = await fetch("/api/comment/save_comment/", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(sendData)
        });
        const data = await response.json()
        if (response.ok){
            setAlertResponse({"success": data["success"]})
            setIsAddCommentContainerOpen(false)
            setFormValue({
                "name": "",
                "comment": ""
            });
            reset()
            setSelectedName("Write as")
        } else {
            setAlertResponse({"error": data["error"]})
        }
        document.body.classList.remove('disable-scroll')
        setIsLoading(false)
    }

    const onChangeSelection = (option) => {
        setSelectedName(option.value)
    }

    const onChangeForm = (e) => {
        const {name, value} = e.target
        setFormValue((prevValue) => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    const onClickSeeAllComments = () => {
        setIsSeeAllComment(!isSeeAllComment)
    }

    const onClickAddCommentFromAllComment = () => {
        setIsSeeAllComment(false)
        onClickAddComment()
    }

  return (
    <>
        <div>
            <p className='text-2xl font-bold text-wrap'>Leave your comment.</p>
            <div className='flex items-end cursor-pointer hover:underline' onClick={onClickAddComment}>
                <p className='text-lg text-slate-400'>Help me improving this website.</p>
                <RateReviewIcon />
            </div>
            <br />
            <p className='cursor-pointer hover:underline' onClick={onClickSeeAllComments}>See all comments.</p>
        </div>
        <div className={`fixed flex top-0 left-0 justify-center items-center backdrop-blur-md w-screen h-screen transition-opacity duration-300 ease-in-out z-10 ${isAddCommentContainerOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}>
            <div className={`flex flex-col  gap-2 w-[50%] max-sm:w-[90%] rounded-lg shadow-box-shadow bg-white p-4 ${isAddCommentContainerOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}>
                <div className='flex items-center justify-between'>
                    <p className='text-md font-bold'>Leave Your Comment</p>
                    <CloseIcon onClick={onClickCancel} />
                </div>
                <form onSubmit={handleSubmit(onClickSave)} className='flex flex-col gap-4' >
                    <Select
                        options={nameSelection.map((item) => ({value: item, label: item}))}
                        // className='portfolio-selection'
                        onChange={(option, action) => (onChangeSelection(option, action))}
                        value={{value: selectedName, label: selectedName}}
                        isDisabled={isSubmitting ? true : false}
                    />
                    {selectFieldAlert && <p className='error-field'>{selectFieldAlert}</p>}
                    {selectedName === "Name" && 
                    <>
                    <div className='w-full border-0.5 border-slate-300 rounded-sm'>
                        <input {...register ("name", {"required": "Name is required"})}
                            value={formValue.name}
                            onChange={(e) => onChangeForm(e)}
                            name="name"
                            className='w-full outline-none h-8 rounded-sm' 
                            placeholder='Name'
                            disabled={isSubmitting ? true : false}
                        />
                    
                    </div>
                    {errors.name && <span className='error-field'>{errors.name.message}</span>}
                    </>
                    
                    }
                    <textarea {...register ("comment", {"required": "Comment is required"})}
                        value={formValue.comment}
                        onChange={(e) => onChangeForm(e)}
                        name="comment"
                        rows={10} 
                        className='w-full border-slate-300 border-0.5 outline-none' 
                        placeholder='Comment'
                        disabled={isSubmitting ? true : false}
                    />
                    {errors.comment && <span className='error-field'>{errors.comment.message}</span>}
                    <div className='flex gap-4 self-end'>
                        <Button buttonType="button" label={isSubmitting ? "Sending..." : "Send"}/>
                    </div>
                </form>
            </div>
        </div>
        {alertResponse && <AlertNotification alertData={alertResponse} />}
        {isLoading &&
        <div className='fixed top-0 left-0'>
            <Loading />
        </div>
        }
        <AllComments isOpen={isSeeAllComment} onClickClose={onClickSeeAllComments} addComment={onClickAddCommentFromAllComment}/>
    </>
    
  )
}

export default ProjectComment