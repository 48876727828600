import React, { createContext, useState } from 'react'

const RemoteSwitchContext = createContext();
export default RemoteSwitchContext;

export const RemoteSwitchButtonProvider = ({ children }) => {
    
    const [isActiveRemoteJob, setIsActiveRemoteJob] = useState(false)
    const [switchButtonStatus, setSwitchButtonStatus] = useState()

    const onChangeSwitch = () => {
        setIsActiveRemoteJob(!isActiveRemoteJob)
    }

    const contextData = {
        onChangeSwitch,
        isActiveRemoteJob,
        setSwitchButtonStatus,
        switchButtonStatus
    }

    return (
        <RemoteSwitchContext.Provider value={contextData}>
            { children }
        </RemoteSwitchContext.Provider>
    )
}