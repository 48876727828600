import React, { createContext, useContext, useState } from 'react'
import ProfileCompletedPercentageContext from './ProfileCompletedPercentage';
import ApplyJobDetail from "components/ApplyJob/ApplyJobDetail";
import ApplyJobAdditionalQuestion from "components/ApplyJob/ApplyJobAdditionalQuestion";
import ApplyJobProfileCompletion from "components/ApplyJob/ApplyJobProfileCompletion";
import AuthContext from './AuthContext';

const ApplyJobContext = createContext();
export default ApplyJobContext;


export const ApplyJobProvider = ({ children }) => {

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [sendCheckingRequired, setSendCheckingRequired] = useState(false)
    const [isAdditionalQuestionCompleted, setIsAdditionalQuestionCompleted] = useState(false)
    const [haveAdditionalQuestion, setHaveAdditionalQuestion] = useState(false)
    const [getJobDetailLoading, setGetJobDetailLoading] = useState(false)
    const [jobData, setJobData] = useState([])
    const [isSendingApplication, setIsSendingApplication] = useState(false)
    const [jobId, setJobId] = useState()
    const [alertResponse, setAlertResponse] = useState()
    const { completedPercentage, getProfileCompletedPercentage } = useContext(ProfileCompletedPercentageContext)
    

    const { user, authToken } = useContext(AuthContext)
    let userToken = null
    if (authToken){
      userToken = authToken.access
    }

    // const { jobId } = useParams();

    let steps;
    let stepsContent;

    if (haveAdditionalQuestion){
        steps = ['Job Details', 'Additional Question', 'Profile Completion'];
        stepsContent = [
            <ApplyJobDetail  getJobDetailLoading={getJobDetailLoading} jobData={jobData} />, 
            <ApplyJobAdditionalQuestion />, 
            <ApplyJobProfileCompletion />
        ]
    } else {
        steps = ['Job Details', 'Profile Completion'];
        stepsContent = [
            <ApplyJobDetail getJobDetailLoading={getJobDetailLoading} jobData={jobData} />, 
            <ApplyJobProfileCompletion />
        ]
    }

    const handleNext = () => {
        window.scrollTo({
            top: "0",
            behavior: "smooth"
        })
        if (activeStep === 0 ){
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
            
            setActiveStep(newActiveStep);
        } else if (steps.length === 3 && activeStep === 1) {
            if (localStorage.getItem("additional_question_answer")){
                const newCompleted = completed;
                newCompleted[activeStep] = true;
                setCompleted(newCompleted);
                const newActiveStep =
                    isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
                
                    setActiveStep(newActiveStep);
            } else {
                setSendCheckingRequired(true)
            }
            setIsAdditionalQuestionCompleted(false)
        }
    };

    const totalSteps = () => {
        return steps?.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleBack = async() => {
        await setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({
            top: "0",
            behavior: "smooth"
        })
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        
        if (completedPercentage === 100){
            onSendApplication()
        } else {
            window.scrollTo({
                top: "0",
                behavior: "smooth"
            })
        }
    };
    
    const onSendApplication = async () => {
        try{
            setIsSendingApplication(true)
            const response = await fetch(`/api/job/interest_job/${jobId}/`, {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `JWT ${userToken}`
                },
                body: localStorage.getItem("additional_question_answer")
            });
            const data = await response.json()
            if (response.ok){

                window.scrollTo({
                    top: "0",
                    behavior: "smooth"
                })

                const newCompleted = completed;
                newCompleted[activeStep] = true;
                setCompleted(newCompleted);
            } else {
                setAlertResponse({"error": data["error"]})
            }
        }catch (error){
            console.error("Encounter an error: ", error);
        } finally {
            setIsSendingApplication(false)
        }
    }
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const contextData = {
        setJobId,
        steps,
        stepsContent,
        setCompleted,
        setActiveStep,
        completed,
        activeStep,
        handleStep,
        allStepsCompleted,
        handleReset,
        handleBack,
        handleNext,
        handleComplete,
        setSendCheckingRequired,
        sendCheckingRequired,
        isAdditionalQuestionCompleted, 
        setIsAdditionalQuestionCompleted,
        setHaveAdditionalQuestion,
        setGetJobDetailLoading,
        setJobData,
        isSendingApplication,
        alertResponse
    }

    return (
        <ApplyJobContext.Provider value={contextData}>
            { children }
        </ApplyJobContext.Provider>
    )
}