import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteConfirmation from 'components/DeleteConfirmation';
import ResumeContext from 'Context/ResumeContext';

const ResumeMoreButton = () => {

    const { 
        isDeleteConfirmationOpen, 
        onOpenCloseDeleteConfirmation,
        onDeleteResume,
        setIsOpenUploadResumeBox,
        onViewResume,
        handleClick,
        handleClose,
        open,
        anchorEl,
        onChangeResume,
        onGetResume
     } = useContext(ResumeContext)
   
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        More
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={onViewResume}>View</MenuItem>
        <MenuItem onClick={onChangeResume}>Change</MenuItem>
        <MenuItem onClick={onOpenCloseDeleteConfirmation} sx={{color: "red"}}><span className=''>Delete</span></MenuItem>
      </Menu>
      {isDeleteConfirmationOpen && <DeleteConfirmation deleteLabel="Delete resume?" onClickYes={onDeleteResume} onClickNo={onOpenCloseDeleteConfirmation} />}
    </div>
  )
}

export default ResumeMoreButton