import React from 'react'

const ContentLoading = () => {
  return (
    <div class="bg-transparent w-[60px] h-[60px]">
        <div class="flex justify-center items-center h-full">
        <img class="h-16 w-16" src="https://icons8.com/preloaders/preloaders/1488/Iphone-spinner-2.gif" alt=""/>
        </div>
    </div>
  )
}

export default ContentLoading