import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from "react-query";

const root = ReactDOM.createRoot(document.getElementById('root'));

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={googleClientId} >
            <App />
        </GoogleOAuthProvider>
    </QueryClientProvider>
);