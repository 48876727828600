import ProfileCompletedPercentageContext from 'Context/ProfileCompletedPercentage'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Divider from '@mui/material/Divider';
import ProfileHeaders from '../Profile/UserProfile/ProfileHeaders';
import ProfileMenu from 'components/Profile/UserProfile/ProfileMenu';
import ProfileInfo from 'components/Profile/UserProfile/ProfileInfo';
import ContentLoading from '../ContentLoading';

const ApplyJobProfileCompletion = () => {

  const navigate = useNavigate()
  const [resumeData, setResumeData] = useState([])
  const [languageUserData, setLanguageUserData] = useState([])
  const [experienceUserData, setExperienceUserData] = useState([])
  const [educationUserData, setEducationUserData] = useState([])
  const [headerUserData, setHeaderUserData] = useState([])
  const [employmentTypeData, setEmploymentTypeData] = useState([])
  const [portfolioUserData, setPortfolioUserData] = useState([])
  const [expectedRateUserData, setExpectedRateUserData] = useState([])
  const [skillsUserData, setSkillsUserData] = useState([])
  const [ratingData, setRatingData] = useState([])
  const [menuClicked, setMenuClicked] = useState()
  const [getProfileLoading, setGetProfileLoading] = useState(false)

  const { completedPercentage, getProfileCompletedPercentage } = useContext(ProfileCompletedPercentageContext)

  const id = localStorage.getItem("userId")

  useEffect(() => {
    const onGetProfile = async () => {
      try {
        setGetProfileLoading(true);
        if (id !== null){
          const response = await fetch(`/api/user/profile/${id.toString()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
          });
          try{
            const data = await response.json();
            if (response.ok) {
  
                setHeaderUserData({
                  userRate: data.rate_ratio,
                  name: data.name,
                  shortIntro: data.short_intro,
                  bio: data.bio,
                  username: data.username,
                  email: data.email,
                  phoneNumber: data.phone_number,
                  profilePicture: data.profile_picture,  // Use the correct method name
                  location: data.userlocation ? data.userlocation["location"]["location"] : null,
                  resume: data.resume
                });
                setResumeData(data.resume)
                setLanguageUserData("profile_picture", data.profile_picture);
                setEmploymentTypeData(data.useremploymenttype)
                setPortfolioUserData(data.portfolios)
                setSkillsUserData(data.skills)
                setLanguageUserData(data.languages);
                setExperienceUserData([{
                  "experience": data.experiences.data,
                  "total_exp": data.experiences.total_exp
                }]);
                setEducationUserData(data.educations);
                setExpectedRateUserData(data.expectedsalary)
                getProfileCompletedPercentage()
              } else if (response.status === 404){
                navigate("/user-not-found/")
              }
            } catch (error){
              navigate("/user-not-found/")
            }
          } 
          else {
            navigate("/user-not-found/")
          }
        }catch (error) {
  
      } finally {
        setGetProfileLoading(false);
      }
    };

    onGetProfile()
  }, [])

  const onMenuClicked = (item) => {
    setMenuClicked(item)
  }

  return (
    <div>
      {getProfileLoading
      ?
         <div className='w-full h-[200px] flex justify-center items-center'>
          <ContentLoading />
        </div>
      :
        <div className=''>
          <div className='flex flex-wrap bg-soft-basic rounded-lg p-10 max-sm:p-5 shadow-box-shadow'>
            <div className='absolute w-full left-0 px-20 max-sm:px-0 max-sm:top-[145px] top-[217px]'>
              <div class="relative mb-5 h-4 rounded-t-lg bg-gray-200">
                <div className={`h-4 animate-pulse rounded-lg rounded-bl-none ${completedPercentage === 100 ? "bg-green-600 rounded-br-none" : "bg-red-500"} `} style={{width: completedPercentage + "%", transition: "width 0.5s ease-in-out" }}></div>
                <span class="absolute inset-0 flex items-center justify-center text-sm font-medium text-gray-900">{completedPercentage}% Completed. {completedPercentage < 100 ? "Please finalize your profile." : "You can proceed."}</span>
              </div>
            </div>
            <ProfileHeaders userData={headerUserData} clickedUserId={id} />
          </div>
          <div className='flex flex-row gap-5 rounded-lg shadow-box-shadow p-10 max-sm:p-0 max-sm:py-10 max-sm:px-w'>
            <div className='flex justify-center w-[20%] h-full sticky top-10 max-sm:hidden' >
              <ProfileMenu menuClicked={onMenuClicked} userId={id} />
            </div>
            <Divider />
            <div className='w-[80%] max-sm:w-full'>
              <ProfileInfo
                skillsUserData={skillsUserData}
                expectedRateUserData={expectedRateUserData}
                experienceUserData={experienceUserData}
                portfolioUserData={portfolioUserData}
                educationUserData={educationUserData}
                employmentTypeData={employmentTypeData}
                languageUserData={languageUserData}
                resumeData={resumeData}
                clickedUserId={id}
              />
            </div>
          </div>
        </div> 
      }
    </div>
  )
}

export default ApplyJobProfileCompletion