import React, { useContext, useEffect, useState } from 'react'
import ResumePdfUpload from 'components/Button/ResumePdfUpload'
import ResumeMoreButton from 'components/Button/ResumeMoreButton'
import AlertNotification from 'components/AlertNotification';
import ResumeContext from 'Context/ResumeContext';
import Button from 'components/Button/Button';
import ViewResume from 'components/ViewResume';

const Resume = ({ resumeData, clickedUserId }) => {

    const {
        onDeleteResume,
        alertResponse,
        isOpenUploadResumeBox, 
        setIsOpenUploadResumeBox,
        resume, 
        setResumeData,
        setClickedProfileId,
        onViewResume,
        pdfFileNameSplit
    } = useContext(ResumeContext)

    useEffect(() => {
        setResumeData(resumeData)
        setClickedProfileId(clickedUserId)
    }, [])

    const userType = localStorage.getItem('userType')

    const onAddResume = () => {
        setIsOpenUploadResumeBox(true)
    }

  return (
    <div className='w-full'>
        <p className='text-[32px] font-bold'>Resume</p>
        <br />
        {resume 
        ?
        userType === "personal"
        ?
        <>
            <div className='flex flex-wrap items-center gap-2 mt-4'>
                <p>{pdfFileNameSplit(String(resume))}</p>
                <ResumeMoreButton onDeleteResume={onDeleteResume} />
            </div>
        </>
        :
        <div className='flex flex-wrap items-center gap-2 mt-4'>
            <p>{pdfFileNameSplit(String(resume))}</p>
            <p className="cursor-pointer font-bold" onClick={onViewResume}>View</p>
        </div>
        :
            userType === "personal" &&
            !isOpenUploadResumeBox && <Button buttonType="button" label="Add resume" clickedButton={onAddResume} />
        }
        {isOpenUploadResumeBox && <ResumePdfUpload resumeData={resumeData} />}
        <ViewResume />
        <AlertNotification alertData={alertResponse} />
    </div>
  )
}

export default Resume