import React, { createContext, useContext, useState } from 'react'
import AuthContext from './AuthContext';
import ProfileCompletedPercentageContext from './ProfileCompletedPercentage';

const ResumeContext = createContext();
export default ResumeContext;


export const ResumeProvider = ({ children }) => {

    const { getProfileCompletedPercentage } = useContext(ProfileCompletedPercentageContext)

    const { authToken } = useContext(AuthContext)
    const userAccess = authToken ? authToken.access : null
    
    const [resume, setResumeData] = useState()
    const [alertResponse, setAlertResponse] = useState("")
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
    const [isOpenUploadResumeBox, setIsOpenUploadResumeBox] = useState(false)
    const [openViewResume, setOpenViewResume] = useState(false)
    const [clickedProfileId, setClickedProfileId] = useState()
    const [isLoadingGetResume, setIsLoadingGetResume] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const onOpenCloseDeleteConfirmation = () => {
        setIsDeleteConfirmationOpen(!isDeleteConfirmationOpen)
    }

    const onDeleteResume = async () => {
        const response = await fetch("/api/user/delete_resume/", {
            method: "DELETE",
            headers: {
                "Authorization": `JWT ${userAccess}`
            }
        });
        const data = await response.json()
        if (response.ok){
            setAlertResponse({"success": data.success})
            setResumeData()
            getProfileCompletedPercentage()
        } else {
            setAlertResponse({"error": data.error})
        }
        setIsDeleteConfirmationOpen(false)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeResume = () => {
        setAnchorEl(null);
        setIsOpenUploadResumeBox(true)
    }

    const onViewResume = () => {
        setOpenViewResume(true)
        onGetResume()
        handleClose()
        document.body.classList.add("disable-scroll");
    }
    
    const onCloseViewResume = () => {
        setOpenViewResume(false)
        document.body.classList.remove("disable-scroll");
    }

    const onGetResume = async() => {
        setIsLoadingGetResume(true)
        const response = await fetch(`/api/user/get_resume/${clickedProfileId}/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
          }
        });
        const data = await response.json();
        if (response.ok){
            setResumeData(data)
        }
        setIsLoadingGetResume(false)
      }

    const pdfFileNameSplit = (filename) => {
        let name = filename.split("/")
        return name[name.length - 1];
    }

    const contextData = {
        onOpenCloseDeleteConfirmation,
        isDeleteConfirmationOpen,
        onDeleteResume,
        alertResponse,
        setAlertResponse,
        isOpenUploadResumeBox, 
        setIsOpenUploadResumeBox,
        resume, 
        setResumeData,
        onViewResume,
        openViewResume,
        onCloseViewResume,
        handleClick,
        handleClose,
        open,
        anchorEl,
        onChangeResume,
        onGetResume,
        setClickedProfileId,
        isLoadingGetResume,
        pdfFileNameSplit
    }

    return (
        <ResumeContext.Provider value={contextData}>
            { children }
        </ResumeContext.Provider>
    )
}