import ResumeContext from 'Context/ResumeContext'
import React, { useContext, useEffect, useState } from 'react'
import ContentLoading from './ContentLoading'

const ViewResume = ( {userId} ) => {

  const { resume, onCloseViewResume, openViewResume, isLoadingGetResume } = useContext(ResumeContext)

  return (
    <div className={`fixed top-0 left-0 w-screen h-screen backdrop-blur-lg z-10 p-10 flex flex-col justify-center items-center gap-4 ${openViewResume ? "visible opacity-100" : "invisible opacity-0" } transition-all duration-500 ease-in-out`}>
        <div className="flex flex-col w-[760px] max-sm:w-full h-[80%] border-[5px] rounded-xl">
          <div className='w-full bg-white flex justify-end'>
            <p className='pr-4 text-xl font-bold cursor-pointer' onClick={onCloseViewResume}>Close</p>
          </div>
          {isLoadingGetResume
          ? 
            <div className='w-full h-full bg-white flex justify-center items-center'>
              <ContentLoading />
            </div>
          :
            <>
              <iframe 
                src={resume}
                width="100%"
                height="100%" 
                allow="autoplay"
                frameborder="0"
              ></iframe>
            </>
          }
        </div>
        <div className="cursor-pointer">
          <a href={resume} download target='_blank' >
            <p className="hover:underline">Download</p>
          </a>
        </div>
      </div>
  )
}

export default ViewResume