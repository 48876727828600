import { Divider } from '@mui/material';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import ApplyJobContext from 'Context/ApplyJobContext';

const ApplyJobAdditionalQuestion = ( props ) => {
    const [fields, setFields] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [requiredField, setRequiredField] = useState([]);
    const [checkingRequiredField, setCheckingRequiredField] = useState([]);
    const [onCheckLengthRequiredField, setOnCheckLengthRequiredField] = useState(false)
    const additionalQuestionData = JSON.parse(localStorage.getItem("additional_question"));
    const {
        handleNext,
        setSendCheckingRequired, 
        sendCheckingRequired, 
        isAdditionalQuestionCompleted, 
        setIsAdditionalQuestionCompleted 
    } = useContext(ApplyJobContext);

    // Add all additional field to the setFields to display it to the user (on return)
    useEffect(() => {
        if (additionalQuestionData) {
            const newFields = additionalQuestionData.map((data, index) => (
                <div key={index}>
                    <div className='flex flex-col gap-2 w-full'>
                        <label>{data[index]?.value} {data[index]?.required && <span className='text-xl text-red-600'>*</span>}</label>
                        {data[index]?.type === "input" 
                        ? 
                        <input 
                            name={`${index}`}
                            className='w-full h-10 outline-none border-0.5 border-border-messages rounded-lg' 
                            onChange={onChangeAnswer}>
                        </input>
                        :
                        <textarea 
                            rows={10} 
                            name={`${index}`}
                            className='border-0.5 border-border-messages rounded-lg outline-none' 
                            onChange={onChangeAnswer}>
                        </textarea>
                        }
                        {checkingRequiredField.includes(index) && <p className='text-red-500'>This field is required</p>}
                    </div>
                </div>
            ));
            setFields(newFields);
        }
    }, [checkingRequiredField]);

    const onChangeAnswer = (e) => {
        const { name, value} = e.target
        setAnswer((prevValue) => ({
            ...prevValue,
            [name]: value
        }))
        setCheckingRequiredField([])
    }

    // List all required field, if true return the index and add to setRequiredField
    useEffect(() => {
        additionalQuestionData.map((data, index) => {
            if (data[index]?.required === true){
                setRequiredField((prevValue) => [...prevValue, index])
            }
        })
    }, [])

    useEffect(() => {
        if (sendCheckingRequired){
            for (let i=0; i < requiredField.length; i++){
                if (!answer[requiredField[i]] || answer[requiredField[i]] === ""){
                    setCheckingRequiredField((prevValue) => [...prevValue, requiredField[i]])
                }
            }
            setOnCheckLengthRequiredField(true)
        }
        setSendCheckingRequired(false)
    }, [sendCheckingRequired])

    useEffect(() => {
        if (onCheckLengthRequiredField){
            if (checkingRequiredField.length === 0){
                handleNext()
                onCombineQuestionWithAnswer()
            }
            setOnCheckLengthRequiredField(false)
        }
    }, [onCheckLengthRequiredField])

    const onCombineQuestionWithAnswer = () => {
        const combination = Object.entries(additionalQuestionData).map(([key, value]) => ({
            [key]: {
                type: value[key].type,
                value: value[key].value,
                required: value[key]["required"],
                answer: answer[key]
              }
        }))
        localStorage.setItem("additional_question_answer", JSON.stringify(combination))
    }

    return (
        <div className='flex flex-col rounded-lg shadow-box-shadow p-10'>
            <div>
                <p className='text-2xl'>Please answer this additional question required by the company.</p>
            </div>
            <br />
            <Divider />
            <div className='py-14 w-full flex justify-center '>
                <div className='w-[50%] max-sm:w-full flex flex-col gap-8'>
                    {fields}
                </div>
            </div>
        </div>
    );
};

export default ApplyJobAdditionalQuestion;
