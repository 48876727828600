import React, { useCallback, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import Button from './Button/Button';

const AllComments = (props) => {

    const [allComments, setAllComments] = useState([])

    useEffect(() => {
        const loadAllComments = async() => {
            const response = await fetch("/api/comment/all_comment/", {
                method: "GET",
                headers: {
                    "content-type": "application/json"
                }
            });
            const data = await response.json()
            if (response.ok){
                setAllComments(data)
            }
        }
        if (props.isOpen === true){
            loadAllComments()
        }
        
    }, [props.isOpen])

    const dateConversion = useCallback((date) => {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Adding 1 because getMonth() returns 0-based index
        const day = dateObject.getDate();
        
        // Extract time components
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const seconds = dateObject.getSeconds();

        // Format the date and time
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

        // Combine date and time
        const dateTime = `${formattedDate} ${formattedTime}`;
        return dateTime
    })

  return (
    <div className={`fixed flex top-0 left-0 justify-center items-center backdrop-blur-md w-screen h-screen transition-opacity duration-300 ease-in-out z-10 ${props.isOpen ? "opacity-100 visible" : "opacity-0 invisible"}`}>
        <div className='flex flex-col w-[50%] max-sm:w-[97%] h-[700px] max-sm:h-[500px] bg-white shadow-lg rounded-lg'>
            <CloseIcon className='self-end mr-10 max-sm:mr-2 my-4' onClick={props.onClickClose} />
            <div className={`px-10 max-sm:px-2 py-6 h-[85%] flex flex-col items-center ${allComments.length === 0 ? "justify-center" : "" } gap-2 overflow-y-auto scroll-smooth`}>
                {allComments.length > 0 ?
                    allComments.map((item) => {
                    const dynamicClass = item.name === "Anonymous" ? "bg-black text-white p-2 rounded-lg" : `bg-soft-basic p-2 rounded-lg`;
                    return (
                        <div className='rounded-xl p-2 border-0.5 w-full'>
                            <div className='flex justify-between'>
                            <p className={dynamicClass}>{item.name}</p>
                                <p className='text-sm text-slate-400'>{dateConversion(item.created_at)}</p>
                            </div>
                            <p className='ml-10 text-wrap break-all py-4'>{item.comment}</p>
                        </div>
                    )
                })
                :
                <>
                    <p>No Comment</p>
                    <br />
                    <Button buttonType="button" label="Add Comment" clickedButton={props.addComment} />
                </>
                }
            </div>
        </div>
    </div>
  )
}

export default AllComments