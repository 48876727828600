import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { format } from 'date-fns';
import { Divider } from '@mui/material';
import ContentLoading from '../ContentLoading';

const ApplyJobDetail = ({ jobId, getJobDetailLoading, jobData }) => { 

  return (
    <div>
    {getJobDetailLoading ?
      <div className='w-full h-[200px] flex justify-center items-center'>
        <ContentLoading />
      </div>
    :
    <div className='flex flex-col rounded-lg shadow-box-shadow'>
        <div className='flex flex-col'>
          <br />
          <div className='px-10'>
            <p className='text-3xl font-bold'>{jobData.job_title}</p>
            <Link to={`/profile/company/?id=${jobData.user_posted?.id}`}>
              <p className='hover:underline my-6'>{jobData.user_posted?.name}</p>
            </Link>
            <div className='flex items-start gap-2 my-6'>
              <LocationOnIcon />
              <ul className='flex flex-wrap gap-2'>
                {jobData.joblocation?.map((item, i) => {
                  return (
                    <li key={i}>{item.location.location} </li>
                  )
                })}
              </ul>
            </div>
            <div className='flex items-center gap-2 my-6'>
              <AccessTimeIcon />
              {jobData.jobemploymenttype?.map((item, i) => {
                return (
                  <p key={i}>{item.employment_type.type}</p>
                )
              })}
            </div>
            <div className='flex items-center gap-2 my-6'>
              <AttachMoneyIcon />
              <p>{jobData.jobsalaryrates?.nominal}</p><span> / </span><p>{jobData.jobsalaryrates?.paid_period}</p>
            </div>
            <div className='flex items-center gap-2 my-6'>
              <PsychologyIcon />
              <p>{jobData.experience_level}</p>
            </div>
            <div className='flex items-center gap-2 my-6'>
              <span>Apply before:</span>
              <p>{jobData?.deadline && format(new Date(jobData?.deadline), 'MM-dd-yyyy')}</p>
            </div>
            
            <div className='w-full'>
                <ul className='pl-0 flex flex-wrap gap-[10px]'>
                {jobData.jobskills?.map((item, i) => {
                  return (
                    <li key={i} className='bg-gray-200 border border-skills-list rounded-md p-1'>{item.skill.skill_name}</li>
                  )
                })}
                </ul>
            </div>
          </div>
        </div>

        <div className='mx-10 mt-0'>
          <br />
          <Divider />
          <p className='text-3xl mt-5 font-bold'>Job Description</p>
          <br />
          {jobData.job_detail?.split("\n").map((line, i) => (
              <Fragment key={i} >
                {i > 0 && <br />}
                <p className="text-md">{line}</p>
              </Fragment>
          ))}
          <br />
          <br />
          <br />
        </div>
      </div>
    }
    </div>
  )
}

export default ApplyJobDetail