import ProfileCompletedPercentageContext from "Context/ProfileCompletedPercentage";
import { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useNavigate } from "react-router-dom";
import ContentLoading from "./ContentLoading";

const CircularProgress = () => {

    const navigate = useNavigate()

    const [showCompleteProfile, setShowCompleteProfile] = useState(false)

    let localLoginUserId = localStorage.getItem("userId")

    const { completedPercentage, getProfileCompletedPercentage, isLoading } = useContext(ProfileCompletedPercentageContext)
    useEffect(() => {
      getProfileCompletedPercentage()

      const timeoutId = setTimeout(() => {
        setShowCompleteProfile(true)
      }, 6000)

      return () => clearTimeout(timeoutId);
    }, [])

    const onClickCompleteNow = () => {
        setShowCompleteProfile(false)
        navigate(`/profile/?id=${localLoginUserId}`)
    }

    const onClickCloseCompleteNowContainer = () => {
        setShowCompleteProfile(false)
    }

  return (
    <div>
      {isLoading ?
        <ContentLoading />
      :
        <>
          <div className={`w-[60px] h-[60px] animate-pulse max-sm:absolute max-sm:top-[50.4px] max-sm:left-[28.4px]`}>
              <CircularProgressbar
                  value={completedPercentage}
                  styles={buildStyles({
                  rotation: 1,
                  strokeLinecap: 'butt',
                  textSize: '16px',
                  pathTransitionDuration: 0.5,
                  pathColor: completedPercentage === 100 ? `rgba(0, 128, 0, ${completedPercentage / 100})` : `rgba(255, 0, 0, ${completedPercentage / 100})`,
                  textColor: '#f88',
                  trailColor: '#d6d6d6',
                  backgroundColor: '#3e98c7',
                  })}
              />
          </div>
          <div className={`absolute max-sm:top-[45px] ${completedPercentage < 10 ? "right-[55px] top-[32px] max-sm:right-[190px]" : completedPercentage === 100 ? "right-[50px] top-[32px] max-sm:right-[170px]" : "right-[52px] top-[33px] max-sm:right-[180px]"} `}>
              <p className='font-bold'>{`${completedPercentage ? completedPercentage :"" }%`}</p>
          </div>
          {completedPercentage < 100 &&
            <div className={`absolute flex flex-col right-10 top-20 w-[250px] max-sm:hidden z-10 ${showCompleteProfile ? "opacity-100" : "opacity-0" } transition-all duration-500 ease-in-out p-4 rounded-xl border-2 border-dark-basic bg-white`}>
                <p>Please complete your profile so the company can see you. <span className="underline cursor-pointer" onClick={onClickCompleteNow}>Complete it now.</span></p>
                <p className="self-end font-bold cursor-pointer" onClick={onClickCloseCompleteNowContainer}>X</p>
            </div>
          }
        </>
      }
    </div>
  )
}

export default CircularProgress