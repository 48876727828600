import React from 'react'
import AddJob from 'components/AddJob'

const AddNewJob = () => {
  return (
    <div>
        <AddJob
            type="add-job-page"
        />
    </div>
  )
}

export default AddNewJob