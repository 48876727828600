import React, { useContext, useRef, useState } from 'react'
import ContentLoading from 'components/ContentLoading';
import AuthContext from 'Context/AuthContext';
import ResumeContext from 'Context/ResumeContext';
import ProfileCompletedPercentageContext from 'Context/ProfileCompletedPercentage';

const ResumePdfUpload = ({ pdfFile }) => {

    const {getProfileCompletedPercentage} = useContext(ProfileCompletedPercentageContext)

    let { authToken } = useContext(AuthContext)
    const userAuthToken = authToken ? authToken.access : null

    const { setAlertResponse, setIsOpenUploadResumeBox, setResumeData, handleClose } = useContext(ResumeContext)

    const fileInputRef = useRef();
    const [isUploadLoading, setIsUploadLoading] = useState(false)

    const handleUploadResume = async() => {
        setIsUploadLoading(true)
        const formData = new FormData()
        const selectedFile = fileInputRef.current.files[0];

        if (selectedFile){
            formData.append('pdf', selectedFile)
            formData.append('name', selectedFile["name"])
        }

        const response = await fetch("/api/user/save_user_resume/", {
            method: "POST",
            headers: {
                "Authorization": `JWT ${userAuthToken}`
            },
            body: formData
        })
        const data = await response.json()
        if (response.ok){
            setResumeData(selectedFile["name"])
            setIsOpenUploadResumeBox(false)
            setAlertResponse({"success": data.success})
            getProfileCompletedPercentage()
            handleClose()
        } else {
            setAlertResponse({"error": data.error})
        }
        setIsUploadLoading(false)
    }
    

  return (
    <div>
        <div class="flex flex-col items-center justify-center w-full">
            <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700  dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div class="flex flex-col items-center justify-center pt-5 pb-6">
                    {isUploadLoading ?
                        <ContentLoading />
                        :
                        <>
                            <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                            </svg>
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">Only Pdf File</p>
                        </>
                    }
                    
                </div>
                <input
                    type='file'
                    id="dropzone-file"
                    ref={fileInputRef}
                    accept='application/pdf'
                    onChange={handleUploadResume}
                    class="hidden"
                />
            </label>
        </div> 
    </div>
  )
}

export default ResumePdfUpload