import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Fragment, useEffect, useRef, useState } from 'react';
import Button from './Button/Button';
import { Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AddOptionContainer from './AddOptionContainer';

const AdditionalQuestion = ({ data }) => {

  const [type, setType] = useState('');
  const [additionalField, setAdditionalField] = useState({})
  const [additionalRequiredField, setAdditionalRequiredField] = useState({})
  const [fields, setFields] = useState([])
  const [isRemovingField, setIsRemovingField] = useState(false)
  const [finalData, setFinalData] = useState([])
  const [checkboxValue, setCheckboxValue] = useState([])
  const [openAddOptionContainer, setOpenAddOptionContainer] = useState({
    value: false,
    key: ""
  })

  const additionalInputFieldId = useRef(0)
  const additionalCheckBoxId = useRef(0)
  const additionalSelectionId = useRef(0)
  const additionalTextAreaId = useRef(0)
  let counter = useRef(0);
  let optionCounter = useRef(0)

  const onAddNewField = (fieldType) => {
    let newField;
    let newId;

    newId = counter.current;

    newField = (
      <div key={newId} className='flex flex-row flex-wrap max-sm:flex-col max-sm:gap-6'>
        <div className='w-[50%] max-sm:w-full'>
          <p className='font-bold'>Type: {fieldType === "input" ? "Input" : "Text Area"}</p>
          <label>Add your question</label>
        </div>
        <div className='w-[50%] max-sm:w-full flex flex-col gap-2'>
          <input 
            name={fieldType === "input" ? `${newId}-input` : `${newId}-textarea`} 
            key={fields.length}
            type="text" 
            className='w-full h-8 border-[1px] rounded-md' 
            onChange={onChangeAdditionalField}
            value={additionalField[newId]?.value}
          />
          <FormControlLabel
            label="Required"
            control={<Checkbox name={newId} checked={additionalRequiredField?.newId?.required} onChange={onChangeAdditionalRequiredField} />}
          />
        </div>
      </div>
    )
    counter.current = counter.current + 1
    setFields((prevFields) => [...prevFields, newField])
  }

  // FOR NEXT VERSION TO OPEN OPTION CONTAINER
  // const onClickAddOption = (e, id) => {
  //   e.preventDefault()
  //   setOpenAddOptionContainer({
  //     value: true, 
  //     key: id
  //   })
  // }

  // FOR NEXT VERSION TO CLOSE OPTION CONTAINER
  // const onClickCloseOptionContainer = (e, id) => {
  //   e.preventDefault()
  //   setOpenAddOptionContainer({
  //     value: false, 
  //     key: ""
  //   })
  // }

  const onChangeAdditionalField = (e) => {
    const { name, value, type } = e.target;
    const fieldType = name.split("-")[1]
    const fieldCount = name.split("-")[0]
    setAdditionalField((prevValue) => ({
      ...prevValue,
      [fieldCount]: {
        type: fieldType,
        value: value,
      }
    }));
  };

  const onChangeAdditionalRequiredField = (e) => {
    const {name, checked} = e.target

    setAdditionalRequiredField((prevValue) => ({
      ...prevValue,
      [name]:{
        required: checked ? checked : false
      }
    }))
  };

  // DO MERGING TO THE INPUT FIELD VALUE AND THE REQUIRED CHECKBOX, MAKE IT BECOME A LIST
  useEffect(() => {
    if (additionalField && additionalRequiredField) {
      const combination = Object.entries(additionalField).map(([key, value]) => ({
          [key]: {
            type: value.type,
            value: value.value,
            required: additionalRequiredField[key] ? additionalRequiredField[key].required : false
          }
        }));
      setFinalData(combination)
      data(combination)
    }
  }, [additionalField, additionalRequiredField]);

  const handleChange = (event) => {
    const fieldType = event.target.value
    onAddNewField(fieldType)
  };


  // IF A FIELD IS REMOVED, FILTER OUT THE FIELD BASED ON THE INDEX AND REMOVE IT ALSO FROM THE FINAL DATA
  const onRemoveField = (key) => {
    setFields(fields.filter((field, index) => field.key !== key));    
    setAdditionalField((prevValue) => {
      const updatedAdditionalField = {...prevValue}
      delete updatedAdditionalField[key]
      return updatedAdditionalField
    })

    setIsRemovingField(true)
  };

  useEffect(() => {
    if (isRemovingField){
      counter.current -= 1
      setAdditionalField(
        Object.values(additionalField).reduce((acc, field, index) => {
          acc[index] = { type: field.type, value: field.value };
          return acc;
        }, {})
      );
      setIsRemovingField(false)
    }
  }, [isRemovingField])

  return (
    <div>
      <div className='flex flex-col gap-12'>
      <form className='flex flex-col gap-12'>
        {fields?.map((field, index) => (
          <Fragment key={field.key}>
            <div className='flex gap-2'>
              <p className='font-bold text-red-500 cursor-pointer' onClick={() => onRemoveField(field.key)}>X</p>
              <div className='w-full'>
                {field}
              </div>
            </div>
            {index !== fields.length - 1 && <Divider />}
          </Fragment>
        ))}        
      </form>
      </div>
      <br />
      {/* <div className='w-[60px] flex justify-center items-center cursor-pointer border-1.5 border-dark-basic bg-dark-basic  text-white font-montserrat rounded-md text-sm transition-all duration-300 hover:bg-white hover:text-dark-basic' onClick={() => onAddNewField("input")}>
        <p className='py-1 font-bold text-2xl' >+</p>
      </div> */}
      
      {/* <Button buttonType="button" label="+ Add" clickedButton={() => onAddNewField("input")} /> */}
      {/* SAVED FOR NEXT VERSION */}
      <div className='w-[200px] z-20'>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Field</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Age"
              onChange={handleChange}
              className="custom-select"
            >
              <MenuItem value={"input"}>Input</MenuItem>
              <MenuItem value={"textarea"}>Text Area</MenuItem>
              {/* <MenuItem value={"checkbox"}>Check Box</MenuItem>
              <MenuItem value={"selection"}>Selection</MenuItem> */}
              
            </Select>
          </FormControl>
        </Box>
      </div>

      {/* FOR NEXT VERSION. FOR CHECKBOX TO ADD OPTION */}
      {/* {openAddOptionContainer.value && <AddOptionContainer checkboxKey={openAddOptionContainer.key} onCloseContainer={onClickCloseOptionContainer} />} */}
    </div>
  )
}

export default AdditionalQuestion
