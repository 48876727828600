import Button from 'components/Button/Button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ApplyJobCompletionStatus = () => {
  
  const navigate = useNavigate()

  const onBackToJobBoard = () => {
    navigate("/")
  }

  return (
    <div className='my-2 py-36 flex flex-col justify-center items-center'>
      <div className='mr-4'>
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
      </div>
      
      <p className='text-2xl'>Application Send!</p>
      <br />
      <br />
      <br />
      <Button buttonType="button" label="Back to job board" clickedButton={onBackToJobBoard} />
    </div>
  )
}

export default ApplyJobCompletionStatus