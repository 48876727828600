import { useParams } from "react-router-dom"
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import ApplyJobContext from "Context/ApplyJobContext";
import AlertNotification from 'components/AlertNotification';
import ApplyJobCompletionStatus from 'components/ApplyJob/ApplyJobCompletionStatus';

const ApplyJob = () => {

    const { jobId } = useParams();

    const { 
        setJobId,
        steps,
        stepsContent,
        setCompleted,
        setActiveStep,
        completed,
        activeStep,
        handleStep,
        allStepsCompleted,
        handleReset,
        handleBack,
        handleNext,
        handleComplete,
        setSendCheckingRequired,
        sendCheckingRequired,
        isAdditionalQuestionCompleted, 
        setIsAdditionalQuestionCompleted,
        setHaveAdditionalQuestion,
        setGetJobDetailLoading,
        setJobData,
        isSendingApplication,
        alertResponse
    } = useContext(ApplyJobContext)

    useEffect(() => {
        setJobId(jobId)
    }, [jobId])

    useEffect(() => {
        setCompleted({})
        setActiveStep(0);
        if (localStorage.getItem("additional_question")){
            localStorage.removeItem("additional_question")
        }
        if (localStorage.getItem("additional_question_answer")){
            localStorage.removeItem("additional_question_answer")
        }

        const onLoadJobDetail = async() => {
          setGetJobDetailLoading(true)
          const response = await fetch(`/api/job/get_job_detail/${jobId}/`, {
            method: "GET",
            headers: {
              "content-type": "application/json"
            }
          })
      
          const data = await response.json()
          if(response.ok){
            setJobData(data)
            if (data.additional_question){
                localStorage.setItem("additional_question", JSON.stringify(data.additional_question))
                setHaveAdditionalQuestion(true)
              }
          }
          setGetJobDetailLoading(false)
        }
    
        onLoadJobDetail()
      }, [])

  return (
    <div className="p-20 max-sm:p-0 max-sm:mt-24">
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep}>
                {steps?.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                    {/* <StepButton color="inherit" onClick={handleStep(index)}>*/}
                    <StepButton color="inherit" sx={{cursor: "default"}} > 
                    {/* <div className="flex gap-2"> */}
                        {/* <p>{index + 1}</p> */}
                        <p>{label}</p>
                    {/* </div> */}
                    </StepButton>
                </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                <Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        <ApplyJobCompletionStatus />
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                </Fragment>
                ) : (
                <Fragment>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                    {/* CONTENT */}
                    {stepsContent[(activeStep - 1) + 1]}

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {/* <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button> */}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep !== steps.length - 1 ?
                        <Button onClick={handleNext} sx={{ mr: 1 }}>
                            Next
                        </Button>
                    :   
                        <div className="">
                            <Button onClick={handleComplete}>{isSendingApplication ? "Sending..." : "Send Application"}</Button>
                        </div>
                    }
                    </Box>
                </Fragment>
                )}
            </div>
        </Box>
        {alertResponse && <AlertNotification alertData={alertResponse}/>}
    </div>
  )
}

export default ApplyJob